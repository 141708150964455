



























































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { api } from "@/store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";
import {
  ApiV0AuditSharesPostShareRequest,
  ApiV0AuditSharesPutShareRequest,
  AuditRemoteSelfAssessmentSettings,
  AuditRemoteSettings,
  LinkShare,
  Share,
  ShareMetadata,
  UserRef,
} from "@auditcloud/shared/lib/schemas";
const auditModule = namespace(api.namespace);
const configModule = namespace(configApi.namespace);

import ASelfAssessmentCard from "@/components/widgets/ASelfAssessmentCard.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import { AuditStatusId, DAY_IN_MS } from "@auditcloud/shared/lib/constants";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import {
  getLinkShares,
  getUserShares,
} from "@auditcloud/shared/lib/utils/shares/utils";
import { Dictionary, isString, toPairs } from "lodash";
import { makeIdable } from "@auditcloud/shared/lib/utils/transform/makeIdable";
import {
  extractSelfAssessmentStatus,
  isShareEditingAllowed,
} from "@auditcloud/shared/lib/utils/audit/auditStatus";
import {
  resolveSelfAssessmentProgress,
  resolveSelfAssessmentStatusText,
} from "@/utils/status_resolver";
import { AuditMetadataClient } from "@/types/Audit";
import naturalCompare from "natural-compare";
import { formatDate } from "@auditcloud/shared/lib/utils/dateUtils";
import { api as usersApi } from "@/store/modules/users";
import { Result } from "neverthrow";

type ShareRepresentation =
  | {
      expired: boolean;
      disabled: boolean;
      type: "link";
      token: string;
      url: string;
      metadata: ShareMetadata;
      id: string;
    }
  | {
      expired: boolean;
      disabled: boolean;
      type: "user";
      userRef: UserRef;
      metadata: ShareMetadata;
      id: string;
    };

@Component({
  components: { ASelfAssessmentCard, ADateChip },
})
export default class ASelfAssessmentSidebar extends Vue {
  linkDueDate: string = new Date(Date.now() + DAY_IN_MS * 14)
    .toISOString()
    .substr(0, 10);
  editorDueDate: string = new Date(Date.now() + DAY_IN_MS * 14)
    .toISOString()
    .substr(0, 10);
  loading: boolean = false;
  assessQuestions: boolean = false;
  message: string = "";
  additionalQuestionListId: string | null = null;
  now = new Date().toISOString();
  timer: null | any = null;

  remoteActivationLoading: boolean = false;
  selfAssessmentCompletionLoading: boolean = false;
  sharesCurrentlyUpdating: Dictionary<boolean> = {};
  linkCopyHint: string | null = null;

  newUserDialog: boolean = false;
  newUserDialogError: string | null = null;
  newUserDialogLoading: boolean = false;

  newLinkShareDialog: boolean = false;
  newUser: UserRef | null = null;
  newUserShareComment: string = "";

  @auditModule.Action(api.actions.addAuditShare)
  addAuditShare!: (
    payload: ApiV0AuditSharesPostShareRequest
  ) => Promise<Result<true, "NOT_ALLOWED_FOR_USER" | "UNKNOWN">>;

  @auditModule.Action(api.actions.updateAuditShare)
  updateAuditShare!: (
    payload: { shareId: string } & ApiV0AuditSharesPutShareRequest
  ) => Promise<any>;

  @auditModule.Action(api.actions.completeSelfAssessment)
  completeSelfAssessment!: () => Promise<any>;

  @auditModule.Getter(api.getters.getAuditId)
  auditId!: nullable<string>;

  @auditModule.Getter(api.getters.getAuditMetadata)
  auditMetadata!: AuditMetadataClient | null;

  @auditModule.Getter(api.getters.getAuditStatus)
  auditStatus!: string | null;

  @auditModule.Getter(api.getters.getAuditRemoteSettings)
  auditRemoteSettings!: AuditRemoteSettings | null;

  @auditModule.Getter(api.getters.getAuditPermissions)
  auditPermissions!: AuditPermissions;

  @auditModule.Getter(api.getters.getSelfAssessmentMarkedAsFinished)
  selfAssessmentMarkedAsFinished!: boolean;

  @auditModule.Getter(api.getters.getSelfAssessmentCompleted)
  selfAssessmentCompleted!: boolean;

  @configModule.Getter(configApi.getters.allowedSelfAssessmentSharingTypes, {
    namespace: configApi.namespace,
  })
  allowedSelfAssessmentSharingTypes!: ("user" | "link")[];

  get useLegacyLinkShareUI() {
    return (
      this.allowedSelfAssessmentSharingTypes.includes("link") &&
      this.allowedSelfAssessmentSharingTypes.length === 1
    );
  }

  get userSharesEnabled() {
    return this.allowedSelfAssessmentSharingTypes.includes("user");
  }

  get linkSharesEnabled() {
    return this.allowedSelfAssessmentSharingTypes.includes("link");
  }

  get writeProtected() {
    return !isShareEditingAllowed(this.auditMetadata);
  }

  get selfAssessmentStatusText() {
    const selfAssessmentStatus = extractSelfAssessmentStatus(
      this.auditMetadata
    );
    const selfAssessmentStatusDescription = this.$ft(
      resolveSelfAssessmentStatusText(selfAssessmentStatus)
    );
    const saAnsweredCount =
      this.auditMetadata?.selfAssessmentAnsweredAuditItemIds.length;
    const saEnabledCount =
      this.auditMetadata?.selfAssessmentEnabledAuditItemIds.length;
    const selfAssessmentProgress = resolveSelfAssessmentProgress(
      selfAssessmentStatus,
      saAnsweredCount,
      saEnabledCount
    );
    return selfAssessmentProgress
      ? `${selfAssessmentStatusDescription} (${selfAssessmentProgress})`
      : selfAssessmentStatusDescription;
  }

  get hasLinkShares() {
    return this.shares.some(share => share.type === "link");
  }

  get linkSharesRepresentation() {
    return this.shareRepresentation.filter(share => share.type === "link");
  }

  get userSharesRepresentation() {
    return this.shareRepresentation.filter(share => share.type === "user");
  }

  get shareRepresentation(): ShareRepresentation[] {
    console.log("Next calc", this.now);
    return this.shares.map(share => {
      const expired = this.now >= share.metadata.expirationDate;
      return {
        ...share,
        expired,
        disabled: expired || share.metadata.state === "disabled",
      };
    });
  }

  get shares(): Array<idable<Share>> {
    const shares =
      this.auditRemoteSettings?.selfAssessment?.sharingSettings.shares ?? {};
    return toPairs(shares)
      .map(makeIdable)
      .sort((lhs, rhs) => {
        if (lhs.type !== rhs.type) {
          return naturalCompare(lhs.type, rhs.type);
        }
        return (
          lhs.metadata.createdAt.localeCompare(rhs.metadata.createdAt) * -1
        );
      });
  }

  get selfAssessmentSettings(): AuditRemoteSelfAssessmentSettings | null {
    return this.auditRemoteSettings?.selfAssessment ?? null;
  }

  get project(): string | null {
    const data = window.TheFirebaseConfig || {};
    return isString(data.projectId) ? data.projectId : null;
  }

  get selfAssessmentUserShares() {
    if (!this.selfAssessmentSettings) {
      return [];
    }
    return getUserShares(this.selfAssessmentSettings.sharingSettings.shares);
  }

  get linkShare(): idable<LinkShare> | null {
    if (!this.selfAssessmentSettings) {
      return null;
    }
    const linkShares = getLinkShares(
      this.selfAssessmentSettings.sharingSettings.shares
    ).filter(v => v.metadata.expirationDate > this.now);

    if (linkShares.length === 0) {
      return null;
    }
    return linkShares[0];
  }

  get remoteLink(): string | null {
    if (!this.linkShare || !this.auditId || !this.project) {
      return null;
    }
    return this.linkShare.url;
  }

  get linkValidUntil(): string {
    if (!this.linkShare) {
      return "";
    }
    const date = this.linkShare.metadata.expirationDate;
    return new Date(date.substring(0, 10)).toLocaleDateString(
      this.$i18n.locale
    );
  }

  get remoteLinkHint(): string | null {
    if (this.linkValidUntil !== "") {
      return (
        this.$t("components.widgets.sidebar.self_assessment.valid_until_date", {
          date: this.linkValidUntil,
        }) + (this.linkCopyHint ? ` (${this.linkCopyHint})` : "")
      );
    }
    return null;
  }

  get permissions() {
    return this.auditPermissions.changeSelfAssessmentStatus;
  }

  get assignedToQueryAction() {
    return {
      name: usersApi.actions.queryUser,
      namespace: usersApi.namespace,
    };
  }

  mounted() {
    this.timer = setInterval(() => {
      this.now = new Date().toISOString();
    }, 10000);
  }

  beforeDestroy() {
    if (this.timer !== null) {
      clearInterval(this.timer ?? undefined);
      this.timer = null;
    }
  }

  addNewLinkShare() {
    this.createRemoteLink();
    this.newLinkShareDialog = false;
  }

  createRemoteLink() {
    this.remoteActivationLoading = true;

    const dueDate = new Date(this.linkDueDate);
    dueDate.setUTCHours(23, 59, 59);

    this.addAuditShare({
      expirationDate: dueDate.toISOString(),
      shareType: "link",
    })
      .then(data => {
        console.log("activateRemote responds", data);
        this.remoteActivationLoading = false;
      })
      .catch(err => {
        console.log("activateRemote failed", err);
        this.remoteActivationLoading = false;
      });
  }

  /**
   * Revoke the "simple" sharing option where only one link share is possible
   */
  revokeSimpleLinkSharing() {
    this.completeSelfAssessment();
    this.revokeLinkAccess();
  }

  revokeLinkAccess() {
    if (!this.linkShare) {
      return;
    }

    this.remoteActivationLoading = true;

    this.updateAuditShare({ shareId: this.linkShare.id, state: "disabled" })
      .then(data => {
        console.log("Revoked link access", data);
        this.remoteActivationLoading = false;
      })
      .catch(err => {
        console.log("Error while revoking link access", err);
        this.remoteActivationLoading = false;
      });
  }

  triggerSelfAssessmentCompletion() {
    this.selfAssessmentCompletionLoading = true;
    this.completeSelfAssessment()
      .catch(e => {
        console.error(
          "triggerSelfAssessmentCompletion: Error while completing self assessment",
          e
        );
      })
      .finally(() => {
        this.selfAssessmentCompletionLoading = false;
      });
  }

  copyAccessLink(shareId: string) {
    const element = this.$refs[shareId] as Vue[];
    const inputElement = element[0].$refs.input as HTMLInputElement;
    console.log("copyAccessLink", element);

    this.copyInputToClipboard(inputElement);
  }

  copyLink() {
    const element = (this.$refs.remoteLink as Vue).$refs
      .input as HTMLInputElement;
    console.log("copyLink", element);

    this.copyInputToClipboard(element);
  }

  copyInputToClipboard(element: HTMLInputElement) {
    element.select();
    element.setSelectionRange(0, 99999); /* For mobile devices */

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.linkCopyHint = this.$t(
          "components.widgets.sidebar.self_assessment.link_copy_hint"
        ).toString();
        window.setTimeout(() => {
          this.linkCopyHint = null;
        }, 3000);
      }
    } catch (err) {
      alert("Oops, unable to copy");
    }

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }

  dismissNewUsers() {
    this.newUserDialog = false;
    this.newUser = null;
  }

  async addNewUsers() {
    this.newUserDialogLoading = true;
    const dueDate = new Date(this.editorDueDate);
    dueDate.setUTCHours(23, 59, 59);

    if (!this.newUser) {
      console.error("Missing user for adding");
      return;
    }

    const result = await this.addAuditShare({
      expirationDate: dueDate.toISOString(),
      shareType: "user",
      userId: this.newUser.id,
      comment: this.newUserShareComment,
    });

    if (result.isOk()) {
      this.newUserDialog = false;
      this.newUser = null;
      this.newUserDialogError = null;
      this.newUserShareComment = "";
    } else if (result.error === "NOT_ALLOWED_FOR_USER") {
      this.newUserDialogError = this.$t(
        "components.widgets.sidebar.self_assessment.new_user_not_allowed_error"
      ).toString();
    }
    this.newUserDialogLoading = false;
  }

  renewAccess(shareId: string) {
    const dueDate = new Date(this.linkDueDate);
    dueDate.setUTCHours(23, 59, 59);

    this.setShareLoading(shareId, true);

    this.updateAuditShare({
      shareId,
      state: "active",
      expirationDate: dueDate.toISOString(),
    })
      .catch(e => {
        console.error(
          `renewAccess: Error while renewing acces for share ${shareId}`,
          e
        );
      })
      .finally(() => {
        this.setShareLoading(shareId, false);
      });
  }

  setShareLoading(shareId: string, state: boolean) {
    if (state) {
      this.$set(this.sharesCurrentlyUpdating, shareId, state);
    } else {
      this.$delete(this.sharesCurrentlyUpdating, shareId);
    }
  }

  revokeAccess(shareId: string) {
    this.setShareLoading(shareId, true);
    this.updateAuditShare({ shareId, state: "disabled" })
      .catch(e => {
        console.error(
          `revokeAccess: Error while revoking acces for share ${shareId}`,
          e
        );
      })
      .finally(() => {
        this.setShareLoading(shareId, false);
      });
  }

  async sendUserShareNotification(shareId: string) {}

  showUserDetail() {}

  formattedDate(date: string) {
    return formatDate(new Date(date), this.$i18n.locale);
  }
}
