
















































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

@Component
export default class ASelfAssessmentCard extends Vue {}
